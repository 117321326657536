@use "../common/breakpoints" as *;
@use "../common/mixin" as *;
@use "../common/color" as *;
@use "../common/font-size" as *;
@use "../common/element" as *;

.footer_wrapper {
  padding: 40px 0 10px 0;
  background: $footer-back-color;

  .footer_logo {
    img {
      height: 100px;
      object-fit: contain;
    }
  }

  .footer_email_subs {
    position: relative;
    margin-bottom: 20px;

    .subs-btn {
      transition: ease-out 1.5s;
      box-shadow: inset 0 0 0 0 #313131;
      &:hover {
        box-shadow: inset 400px 0 0 0 $themecolor;
        color: #fff;
      }
    }

    input {
      width: 95%;
      padding-left: 30px;
      border-radius: 30px;
      height: 42px;
    }

    button {
      position: absolute;
      right: 19px;
      top: 0;
      bottom: 0;
      border-radius: 30px;
      background-color: $themecolor;
      margin: 4px;
      @include flex-center;
    }
  }

  .footer_left_second {
    .footer_title {
      .footer_heading {
        color: #fff;
        text-transform: capitalize;
        font-family: $font-family-prop;
      }
    }

    .footer_policy {
      list-style-type: none;
      padding-left: 0;

      li {
        padding-bottom: 10px;
        cursor: pointer;
      }
    }
  }

  .footer_right {
    .footer_title {
      .footer_heading {
        color: #fff;
        text-transform: capitalize;
        font-family: $font-family-prop;
      }
    }
    .footer_policy {
      list-style-type: none;
      padding-left: 0;

      li {
        padding-bottom: 10px;
        cursor: pointer;
        a {
          color: inherit;
        }
      }
    }

    .footer_social {
      list-style-type: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 0;

      li {
        border: 2px dashed #fff3;;
        border-radius: 100%;
        width: 30px;
        height: 30px;
        margin-right: 20px;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:hover {
          color: $themecolor;
          border: 1px solid $themecolor;
          transition: $baseTransition;
        }
      }
    }
  }
  .footer_left_text {
    padding-bottom: 10px;
  }
  @media #{$min-992-device} {
    .col-lg-3 {
      width: 20%;

      &.footer_left {
        width: 35%;
        .footer_left_text {
          width: 75%;
        }
        .footer_email_subs {
          width: 75%;
        }
      }

      &.footer_left_second {
        width: 25%;
      }
    }
  }
  .playstore_img{
    img{
      width: 150px;
      object-fit: contain;
      margin-bottom: 20px;
    }
  }

  .footer_list{
    a{
      color: inherit;
    }
  }
}
