/*----------------------------------------
        06. Intro CSS
----------------------------------------*/

@use "../common/breakpoints" as *;
@use "../common/mixin" as *;
@use "../common/color" as *;

.intro-slider {
  &:hover {
    .main-slider-nav {
      opacity: 1;
      visibility: visible;
    }
  }
  .main-slider-nav {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.25);
    width: 100px;
    height: 100px;
    line-height: 100px;
    display: block;
    text-align: center;
    color: $white;
    opacity: 0;
    visibility: hidden;
    @include common-transition;
    &::after {
      display: none;
    }

    i {
      font-size: 40px;
      line-height: 1;
      vertical-align: middle;

      @media #{$laptop-device} {
        font-size: 30px;
      }

      @media #{$tablet-device} {
        font-size: 24px;
      }

      @media #{$desktop-device} {
        font-size: 24px;
      }

      @media #{$large-device} {
        font-size: 24px;
      }
    }

    &:hover {
      color: #1292ee;
    }

    @media #{$laptop-device} {
      width: 80px;
      height: 80px;
      line-height: 80px;
    }
    @media #{$tablet-device} {
      width: 60px;
      height: 60px;
      line-height: 60px;
    }
    @media #{$desktop-device} {
      width: 60px;
      height: 60px;
      line-height: 60px;
    }
    @media #{$large-device} {
      width: 50px;
      height: 50px;
      line-height: 50px;
    }
  }

  .home-slider-prev {
    left: 30px;
  }

  .home-slider-next {
    right: 30px;

    &::after {
      display: none;
    }
  }
}

/* slider */
.intro-slider-wrap {
  background-color: #fff;
  margin-top: -112px;
  padding-top: 100px;

  @media #{$extra-small-device} {
    @include flex-center;
    background-color: transparent;
  }

  .swiper-container {
    @media #{$extra-small-device} {
      padding: 30px 0;
    }
  }

  .slide1 {
    .slider1__btn {
      margin-right: 10px;
      transition: ease-out 0.4s;

      &:hover {
        box-shadow: inset 400px 0 0 0 $themecolor;
      }
    }
  }

  .slider1__btn2 {
    background-color: $mate-black;
    border-radius: 5px;
  }

  .swiper-button-prev {
    left: 50px !important;
    background-image: linear-gradient(93deg, $gradient-one 0%, $gradient-two 100%) !important;
    color: #fff !important;
    padding: 30px;
    border-radius: 50%;
    transition: ease-out 0.4s;

    &:hover {
      background-color: $themecolor !important;
      overflow: hidden;

      &::after {
        animation: moveoutleft 1s linear;
      }

      @keyframes moveoutleft {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-100px);
        }
      }
    }

    @media #{$extra-small-device} {
      display: none;
    }
  }

  .swiper-button-next {
    right: 50px !important;
    background-image: linear-gradient(93deg, $gradient-one 0%, $gradient-two 100%) !important;
    color: #fff !important;
    padding: 30px;
    border-radius: 50%;
    transition: ease-out 0.4s;

    &:hover {
      background-color: $themecolor !important;
      overflow: hidden;

      &::after {
        animation: moveoutright 1s linear;
      }

      @keyframes moveoutright {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(100px);
        }
      }
    }

    @media #{$extra-small-device} {
      display: none;
    }
  }
}

.swiper-slide-active .intro-content > * {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.swiper-slide-active .intro-content > *:nth-child(1) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.swiper-slide-active .intro-content > *:nth-child(2) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.swiper-slide-active .intro-content > *:nth-child(3) {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}
.swiper-slide-active .intro-content > *:nth-child(4) {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.swiper-slide-active .intro-content > *:nth-child(5) {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}
.swiper-slide-active .intro-content > *:nth-child(6) {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.innerslider {
  height: 80vh;
  position: relative;
  z-index: 99;
  width: 100%;
  margin: auto;
  overflow: visible;
  background-size: cover;
  background-repeat: no-repeat;

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
  }
}

.ityped-cursor {
  opacity: 1;
  color: $themecolor;
  -webkit-animation: blink 1s infinite;
  -moz-animation: blink 1s infinite;
  animation-direction: alternate;
  animation: blink 1s infinite;
}

.itypetext {
  color: $themecolor;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  color: $white;
}

.mySwiper {
  height: 80vh;
  width: 100%;

  @media #{$max-wid-device} {
    height: 100%;
  }
}

.slide1 {
  display: flex;
  align-items: center;
  position: relative;
  height: 80vh;
  padding-bottom: 40px;
  text-align: left;
}

.slide2 {
  display: flex;
  align-items: center;
  position: relative;
  height: 80vh;
  padding-bottom: 40px;
  text-align: left;
}

.slide3 {
  display: flex;
  align-items: center;
  position: relative;
  height: 80vh;
  padding-bottom: 40px;
  text-align: left;
}

@media #{$max-wid-device} {
  .slide1,
  .slide2,
  .slide3 {
    height: 100%;
    margin: 20px auto;
  }
}

@media #{$extra-small-device} {
  .slide1,
  .slide2,
  .slide3 {
    height: auto;
    margin: auto;
  }
}

.slide1 {
  @media #{$extra-small-device} {
    text-align: center;
  }
  h1 {
    color: $white;
    font-size: 4rem;
    font-weight: 900;

    @media #{$extra-small-device} {
      font-size: inherit;
    }
  }

  .slider__content {
    p {
      font-size: 2rem;
      font-weight: 600;

      @media #{$extra-small-device} {
        font-size: inherit;
      }
    }
  }

  .outer__slide1__img {
    background-position: center center;
    background-repeat: no-repeat;
    height: 650px;
    width: 100%;
    position: relative;
    max-width: 100%;
    max-height: 100%;
    margin-left: -85px;

    @media #{$extra-small-device} {
      height: auto;
      background-image: none !important;
    }

    img {
      display: flex;
      margin: auto;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0;
      right: 0;
      max-width: 100%;

      @media #{$extra-small-device} {
        position: inherit;
        height: 300px;
        object-fit: contain;
        margin-bottom: 40px;
      }
    }
  }
}

.slide2 {
  @media #{$extra-small-device} {
    text-align: center;
  }
  h3 {
    font-size: 2rem;
    font-weight: 900;
    @media #{$extra-small-device} {
      font-size: inherit;
      line-height: 1.5;
    }
  }

  .highlighttext {
    color: $themecolor;
    font-size: 2rem;
    @media #{$extra-small-device} {
      font-size: inherit;
    }
  }

  .outer__slide1__img {
    background-position: center center;
    background-repeat: no-repeat;
    height: 600px;
    width: 100%;
    position: relative;
    max-width: 100%;
    max-height: 100%;
    margin-left: -85px;

    @media #{$extra-small-device} {
      height: auto;
      background-image: none !important;
    }

    img {
      @include flex-center;
      margin: auto;
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0;
      right: 0;
      max-width: 100%;
      width: 50%;

      @media #{$extra-small-device} {
        position: inherit;
        height: 280px;
        object-fit: contain;
        margin-bottom: 40px;
        width: 100%;
      }
    }
  }
}

.slide3 {
  @media #{$extra-small-device} {
    text-align: center;
  }

  h3 {
    color: $white;
    font-size: 2rem;
    font-weight: 300;
    @media #{$extra-small-device} {
      font-size: inherit;
      line-height: 1.5;
    }
  }

  .highlighttext {
    color: $themecolor;
    font-size: 3rem;
    @media #{$extra-small-device} {
      font-size: inherit;
    }
  }

  .slider__content {
    p {
      text-transform: capitalize;
      @media #{$extra-small-device} {
        font-size: inherit;
      }
    }
  }

  .outer__slide1__img {
    background-position: center center;
    background-repeat: no-repeat;
    height: 650px;
    width: 100%;
    position: relative;
    max-width: 100%;
    max-height: 100%;
    margin-left: -85px;

    @media #{$extra-small-device} {
      height: auto;
      background-image: none !important;
    }
    img {
      @include flex-center;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      max-width: 100%;
      width: 50%;

      @media #{$extra-small-device} {
        position: inherit;
        height: 300px;
        object-fit: contain;
        margin-bottom: 40px;
        width: 100%;
      }
    }
  }
}

@media #{$custom-max-1399-device} {
  .slide1 .outer__slide1__img {
    margin-left: 0;
  }

  .slide2 .outer__slide1__img {
    margin-left: 0;
  }

  .slide3 .outer__slide1__img {
    margin-left: 0;
  }
}

@media #{$min-768-device} {
  .slide1 .outer__slide1__img img,
  .slide2 .outer__slide1__img img,
  .slide3 .outer__slide1__img img {
    left: 0;
    top: 0;
  }
}

.swiper-button-prev::after {
  font-size: 0.8rem !important;
}

.swiper-button-next::after {
  font-size: 0.8rem !important;
}

.outer__slide1__img img {
  animation: updown 1s infinite alternate;
}

@keyframes updown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.slider1__btn {
  transition: ease-out 2s;
  display: inline-block;
  box-shadow: inset 0 0 0 0 #313131;
  border: none;

  &:hover {
    box-shadow: inset 400px 0 0 0 #313131;
  }
}

.slider1__btn2 {
  transition: ease-out 2s;
  display: inline-block;
  box-shadow: inset 0 0 0 0 $themecolor;

  &:hover {
    box-shadow: inset 400px 0 0 0 $themecolor;
  }
}

.slider__content {
  h3,
  p {
    color: #313131;
  }
}
